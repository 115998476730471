import { css } from 'styled-components'

import { fonts } from '../variables'

const { weights, sizes } = fonts

// Use helper function from src/styles/variables/spaces.js to generate VALUES
const VALUES = [
  0, 4, 5, 8, 10, 12, 15, 16, 20, 24, 25, 28, 30, 32, 35, 36, 40, 44, 45, 48,
  50, 52, 54,
]

export function fontWeights() {
  return Object.keys(weights).map(
    value => css`
      .fw-${value} {
        font-weight: ${weights[value]};
      }
    `
  )
}

export function fontSizes() {
  return Object.keys(sizes).map(
    size => css`
      .fs-${size} {
        font-size: ${sizes[size]}px;
      }
    `
  )
}

export function lineHeights() {
  return css`
    ${VALUES.map(
      value => css`
        .lh-${value} {
          line-height: ${value}px;
        }
      `
    )}
    .lh-n {
      line-height: normal;
    }
  `
}

export function textAligns() {
  return css`
    .ta-l {
      text-align: left;
    }
    .ta-c {
      text-align: center;
    }
    .ta-r {
      text-align: right;
    }
    .ta-j {
      text-align: justify;
    }
  `
}
