// Use spaceGenerator to generate the spaces array with hardcoded values
// Gain of performance in dev mode for hot reloads
// const MAX = 150

// const spaceGenerator = () =>
//   Array.from({ length: MAX + 1 }, (_, i) => i).filter(
//     i => ((i % 4 === 0 || i % 5 === 0) && i <= 120) || i === MAX
//   )

export const spaces = [
  0, 4, 5, 8, 10, 12, 15, 16, 20, 24, 25, 28, 30, 32, 35, 36, 40, 44, 45, 48,
  50, 52, 55, 56, 60, 64, 65, 68, 70, 72, 75, 76, 80, 84, 85, 88, 90, 92, 95,
  96, 100, 104, 105, 108, 110, 112, 115, 116, 120, 150,
]
