import { css } from 'styled-components'

import { spaces } from '../variables'

const directions = ['top', 'right', 'bottom', 'left']

export function margins() {
  return spaces.map(
    value => css`
      ${directions.map(
        direction => css`
        .m${direction[0]}-${value} {
          margin-${direction}: ${value}px;
        }
      `
      )}
      .m-${value} {
        margin: ${value}px;
      }
      .mx-${value} {
        margin-left: ${value}px;
        margin-right: ${value}px;
      }
      .my-${value} {
        margin-top: ${value}px;
        margin-bottom: ${value}px;
      }
    `
  )
}

export function marginsAuto() {
  return directions.map(
    direction => css`
    .m${direction[0]}-a {
      margin-${direction}: auto;
    }
  `
  )
}

export function spaceChildren() {
  return directions.map(
    direction => css`
      ${spaces.map(
        value => css`
      .sc${direction[0]}-${value} {
        & > * + * {
          margin-${direction}: ${value}px;
        }
      }
    `
      )}
    `
  )
}

export function paddings() {
  return spaces.map(
    value => css`
      ${directions.map(
        direction => css`
        .p${direction[0]}-${value} {
          padding-${direction}: ${value}px;
        }
      `
      )}
      .p-${value} {
        padding: ${value}px;
      }
      .px-${value} {
        padding-left: ${value}px;
        padding-right: ${value}px;
      }
      .py-${value} {
        padding-top: ${value}px;
        padding-bottom: ${value}px;
      }
    `
  )
}
