import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import defaultStyle from './defaultStyle'
import {
  backgroundColors,
  colors,
  flexboxes,
  fontWeights,
  fontSizes,
  lineHeights,
  margins,
  marginsAuto,
  spaceChildren,
  paddings,
  textAligns,
} from '../classnames'

export const GlobalStyle = createGlobalStyle`
  ${reset}
  ${defaultStyle}
  ${backgroundColors}
  ${colors}
  ${flexboxes}
  ${fontWeights}
  ${fontSizes}
  ${lineHeights}
  ${margins}
  ${marginsAuto}
  ${spaceChildren}
  ${paddings}
  ${textAligns}
`
