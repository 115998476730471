export const colors = {
  black: '#000000',
  blue: '#2F96DD',
  blueDark: '#0F283D', // Figma color name: BG - Dark
  blueLight: '#64C5FF',
  gray: '#999999', // Figma color name: Input
  grayDark: '#54595F', // Figma color name: Ambassadeurs
  green: '#329E8D',
  greenDeep: '#102420', // Figma color name: Typo
  greenLight: '#26D08D',
  white: '#ffffff',
  whiteOff: '#FCF6E8', // Figma color name: BG - Light
  yellow: '#FFD500',
  yellowLight: '#FFFC35',
}
