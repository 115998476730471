import { css } from 'styled-components'

import { colors as _colors } from '../variables'

export function colors() {
  return Object.keys(_colors).map(
    color => css`
      .c-${color} {
        color: ${_colors[color]};
      }
    `
  )
}
