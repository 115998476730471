import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme, { GlobalStyle } from 'styles/theme'
import Password from 'components/dev/password'

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={{ ...theme }}>
    <GlobalStyle />
    <Password>
      {element}
    </Password>
  </ThemeProvider>
)
