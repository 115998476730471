import React, { useState, useEffect } from 'react'

export default function Password({ children }) {
  // Light password protection to avoid search engine indexing and form submission
  const [canAccess, setCanAccess] = useState(process.env.NODE_ENV !== 'staging')

  useEffect(() => {
    const checkPwd = () => {
      if (localStorage.getItem('pwd') === process.env.GATSBY_STAGING_PWD) {
        setCanAccess(true)
        return
      }

      const pwd = prompt()
      if (pwd === process.env.GATSBY_STAGING_PWD) {
        setCanAccess(true)
        localStorage.setItem('pwd', process.env.GATSBY_STAGING_PWD)
      } else {
        checkPwd()
      }
    }
    if (process.env.NODE_ENV === 'staging') {
      checkPwd()
    }
  }, [])

  if (!canAccess) {
    return <div />
  }

  return children
}