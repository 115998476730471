import { css } from 'styled-components'

import { colors } from '../variables'

export function backgroundColors() {
  return Object.keys(colors).map(
    color => css`
      .bgc-${color} {
        background-color: ${colors[color]};
      }
    `
  )
}
