import { css } from 'styled-components'

export function flexboxes() {
  return css`
    .flex {
      display: flex;
    }
    .flex-row {
      flex-direction: row;
    }
    .flex-column {
      flex-direction: column;
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    .flex-nowrap {
      flex-wrap: nowrap;
    }
    .flex-wrap-reverse {
      flex-wrap: wrap-reverse;
    }
    .flex-row-reverse {
      flex-direction: row-reverse;
    }
    .flex-column-reverse {
      flex-direction: column-reverse;
    }
    .justify-start {
      justify-content: flex-start;
    }
    .justify-end {
      justify-content: flex-end;
    }
    .justify-center {
      justify-content: center;
    }
    .justify-between {
      justify-content: space-between;
    }
    .justify-around {
      justify-content: space-around;
    }
    .align-start {
      align-items: flex-start;
    }
    .align-end {
      align-items: flex-end;
    }
    .align-center {
      align-items: center;
    }
    .align-baseline {
      align-items: baseline;
    }
    .align-stretch {
      align-items: stretch;
    }
    .align-content-start {
      align-content: flex-start;
    }
    .align-content-end {
      align-content: flex-end;
    }
    .align-content-center {
      align-content: center;
    }
    .align-content-between {
      align-content: space-between;
    }
    .align-content-around {
      align-content: space-around;
    }
    .align-content-stretch {
      align-content: stretch;
    }
    .self-start {
      align-self: flex-start;
    }
    .self-end {
      align-self: flex-end;
    }
    .self-center {
      align-self: center;
    }
    .self-baseline {
      align-self: baseline;
    }
    .self-stretch {
      align-self: stretch;
    }
    .flex-grow-0 {
      flex-grow: 0;
    }
    .flex-grow-1 {
      flex-grow: 1;
    }
    .flex-shrink-0 {
      flex-shrink: 0;
    }
    .flex-shrink-1 {
      flex-shrink: 1;
    }
    .flex-auto {
      flex: 1 1 auto;
    }
    .flex-initial {
      flex: 0 1 auto;
    }
    .flex-none {
      flex: none;
    }
  `
}
