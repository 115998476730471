import { css } from 'styled-components'

import { fonts, colors } from '../variables'

export default css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${colors.whiteOff};
    box-sizing: border-box;
    color: ${colors.greenDeep};
    font-family: ${fonts.family.default};
    font-size: ${fonts.sizes.md}px;
    line-height: normal;
  }
`
