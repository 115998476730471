export const fonts = {
  family: {
    default: `Montserrat, '-apple-system', BlinkMacSystemFont, 'Segoe UI', Arial, 'Apple Color Emoji', 'sans-serif'`,
  },
  weights: {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  sizes: {
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 20,
    xl: 24,
    '2xl': 30,
    '3xl': 40,
    '4xl': 54,
  },
}
