module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tricefal","short_name":"tricefal","description":"Consultants IT & Métier by tricefal®","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/images/meta/icon.png","icons":[{"src":"static/images/meta/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/images/meta/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/images/meta/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"crossOrigin":"use-credentials","lang":"fr","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"9aed695e1ce96cfa743bcd16befc19f6"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
